// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const DETAILS = document.querySelector(".toolbar__details");

/**
 * When the user clicks away from the details or any child, add a class
 */
document.addEventListener("click", (e) => {
    if (DETAILS && DETAILS.open == true && ! DETAILS.contains(e.target)) {
        DETAILS.open = false;
    }
}, { passive: true });
