// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

const SLIDESHOW = document.querySelector(".swiper-container--slideshow");

if (SLIDESHOW && SLIDESHOW.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper (SLIDESHOW, {
        modules: [Autoplay, EffectFade, Navigation, Pagination],
        autoplay: {
            delay: 7500,
        },
        effect: "fade",
        loop: true,
        navigation: {
            nextEl: SLIDESHOW.querySelector(".swiper-button--next"),
            prevEl: SLIDESHOW.querySelector(".swiper-button--prev"),
        },
        pagination: {
            el: SLIDESHOW.querySelector(".swiper-pagination"),
            clickable: true,
        },
        speed: 150,
    });
}

const BOOK_LISTS = document.querySelectorAll(".swiper-container--book-list");

if (BOOK_LISTS.length > 0) {
    BOOK_LISTS.forEach((book_list) => {
        let breakpoints ={
            480: {
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
            640: {
                slidesPerGroup: 4,
                slidesPerView: 4,
            },
            768: {
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            853: {
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
            960: {
                slidesPerGroup: 4,
                slidesPerView: 4,
            },
            1024: {
                slidesPerGroup: 5,
                slidesPerView: 5,
            },
            1280: {
                slidesPerGroup: 6,
                slidesPerView: 6,
            },
        };

        if (book_list.classList.contains("swiper-container--book-list--large")) {
            breakpoints = {
                480: {
                    slidesPerGroup: 3,
                    slidesPerView: 3,
                },
                768: {
                    slidesPerGroup: 4,
                    slidesPerView: 4,
                },
                960: {
                    slidesPerGroup: 5,
                    slidesPerView: 5,
                },
            };
        }

        new Swiper(book_list, {
            modules: [Navigation],
            loop: true,
            navigation: {
                nextEl: book_list.parentElement.querySelector(".swiper-button--next"),
            },
            slidesPerGroup: 2,
            slidesPerView: 2,
            spaceBetween: 45,
            breakpoints,
        });
    });
}
