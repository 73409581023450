// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import handorgel from "handorgel";

const UPDATE_VIA_HASH = (handorgel, hash) => {
    const TARGET_ID  = hash.replace(/^#/, "");
    const OLD_TARGET = document.querySelector(".hash-target");

    if (OLD_TARGET) {
        OLD_TARGET.classList.remove("hash-target");
    }

    requestAnimationFrame(() => {
        handorgel.folds.forEach((fold) => {
            if ((TARGET_ID.match(/^[A-Za-z]/) && fold.content.querySelector(`#${TARGET_ID}`)) || fold.header.dataset.id === TARGET_ID) {
                fold.open();
            }

            if (fold.header.dataset.id === TARGET_ID) {
                fold.header.classList.add("hash-target");
            } else if (hash.match(/^#[A-Za-z]/)  && fold.content.querySelector(hash)) {
                fold.content.querySelector(hash).classList.add("hash-target");
            }
        });
    });
};

const PARAMS     = new URLSearchParams(window.location.search);
const HANDORGELS = document.querySelectorAll(".handorgel");

HANDORGELS.forEach((element) => {
    const HANDORGEL = new handorgel(element);

    /**
     * Scroll to hash when an accordion finishes opening
     */
    HANDORGEL.on("fold:opened", (fold) => {
        requestAnimationFrame(() => {
            if (window.location.hash && (fold.header.classList.contains("hash-target") || fold.content.querySelector(".hash-target") || (window.location.hash.match(/^#[A-Za-z]/) && (fold.header.querySelector(window.location.hash) || fold.content.querySelector(window.location.hash))))) {
                document.querySelector(".hash-target").scrollIntoView();
            } else {
                window.location.hash = fold.header.dataset.id;
            }
        });
    });

    /**
     * Fix Firefox bug
     */
    HANDORGEL.on("fold:close", (fold) => {
        setTimeout(() => {
            fold.header.classList.remove("handorgel__header--open");
            fold.content.classList.remove("handorgel__content--open");
        }, 100);
    });

    if (PARAMS.get("highlight")) {
        /**
         * Open accordions targeted by Relevanssi on load
         */
        HANDORGEL.folds.forEach((fold) => {
            if (fold.content.querySelector("mark")) {
                fold.open();
            }
        });
    } else if (window.location.hash) {
        /**
         * Open accordions targeted by a URL hash on load
         */
        UPDATE_VIA_HASH(HANDORGEL, window.location.hash);
    }

    /**
     * Open accordions targeted by a URL hash on hashchange
     */
    window.addEventListener("hashchange", () => {
        UPDATE_VIA_HASH(HANDORGEL, window.location.hash);
    });
});
