// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { throttle } from "throttle-debounce";

const BUTTON = document.querySelector(".scroll-top");

if (BUTTON) {
    BUTTON.addEventListener("click", (e) => {
        e.preventDefault();

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, { passive: false });

    window.addEventListener("scroll", throttle(100, () => {
        BUTTON.classList.toggle("is-active", ! ((window.scrollY + window.innerHeight) + 100 >= document.body.scrollHeight) && (window.scrollY > window.innerHeight * 1.5));
    }, { passive: true }));
}
