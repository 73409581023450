// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const FORMS = document.querySelectorAll(".search-form");

FORMS.forEach((form) => {
    const PROVIDER = form.querySelector("[name='provider']");

    if (PROVIDER) {
        PROVIDER.addEventListener("change", () => {
            form.setAttribute("target", PROVIDER.value === "website" ? "_self" : "_blank");
            PROVIDER.classList.toggle("search-form__input--internal", PROVIDER.value === "website");
        });
    }
});
